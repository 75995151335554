export default {
  sdt: {
    97: '0x063BD53fEF3F352eA9388c59c5aD9f524836A936',
    56: '0x543c7ebb52d56985f63f246a5b3558aff79037d7'
  },
  busd: {
    97: '0x78867BbEeF44f2326bF8DDd1941a4439382EF2A7',
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56'
  },
  usdt: {
    97: '0x7ef95a0fee0dd31b22626fa2e10ee6a223f8a684',
    56: '0x55d398326f99059ff775485246999027b3197955'
  },
  wbnb: {
    97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'
  },
  masterChef: {
    97: '0x33f1318EaaF0F4f957900708Fe961f3d6357ca80',
    56: '0x159372CC202D2D29D349E608A1AE6daf6482C304'
  },
  multiCall: {
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb'
  }
}
