import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
// components
import LoadingScreen from './components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard" replace />, index: true },
        { path: '/dashboard', element: <Dashboard /> },
        { path: '/stake', element: <Stake /> },
        { path: '/farm', element: <Farm /> },
        { path: '/news', element: <News /> },
      ],
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
    // { path: '/', element: <Countdown /> },
  ]);
}

// Dashboard
const Dashboard = Loadable(lazy(() => import('./pages/Dashboard')));
const Stake = Loadable(lazy(() => import('./pages/Stake')));
const Farm = Loadable(lazy(() => import('./pages/Farm')));
const News = Loadable(lazy(() => import('./pages/News')));
const Countdown = Loadable(lazy(() => import('./pages/Countdown')));
const NotFound = Loadable(lazy(() => import('./pages/Page404')));
