// scroll bar
import 'simplebar/src/simplebar.css';
import './index.css';

import { createStore } from 'redux'
import { Provider } from 'react-redux'

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

import 'react-toastify/dist/ReactToastify.css';

import ReactDOM from 'react-dom';
import { StrictMode } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import { ModalProvider } from 'strike-uikit1.0'
import bsc, { UseWalletProvider } from '@binance-chain/bsc-use-wallet'
import getRpcUrl from './utils/getRpcUrl'
import { ThemeContextProvider } from './contexts/ThemeContext'

// contexts
import { SettingsProvider } from './contexts/SettingsContext';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';
//
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import store from './state'

import reducer from './reducers'
import middleware from './middleware'
// ----------------------------------------------------------------------

// const store = createStore(reducer, middleware)

const rpcUrl = getRpcUrl()

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <HelmetProvider>
        <SettingsProvider>
          <CollapseDrawerProvider>
            <BrowserRouter>
              <ThemeContextProvider>
                <UseWalletProvider
                  chainId={parseInt(process.env.REACT_APP_CHAIN_ID, 10)}
                  connectors={{
                    walletconnect: { rpcUrl },
                    bsc
                  }}
                >
                  <ModalProvider>
                    <App/>
                  </ModalProvider>
                  </UseWalletProvider>
              </ThemeContextProvider>
            </BrowserRouter>
          </CollapseDrawerProvider>
        </SettingsProvider>
      </HelmetProvider>
    </Provider>
  </StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
