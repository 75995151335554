import React from 'react'
import { useWallet } from '@binance-chain/bsc-use-wallet'
import { Button } from '@mui/material';
import { useWalletModal } from 'strike-uikit1.0'
import { formatWalletAddress } from '../utils/helpers';

const ConnectButton = (props) => {
  const { connect, reset, account } = useWallet()
  const { onPresentConnectModal, onPresentAccountModal } = useWalletModal(connect, reset, account)

  return (
    <div>
      { account ? 
        <Button variant="contained" onClick={onPresentAccountModal} {...props} style={{fontSize: "12px"}}>{formatWalletAddress(account)}</Button>
      :  
      <Button variant="contained" onClick={onPresentConnectModal} {...props}>
        Connect wallet
      </Button>
      }
    </div>
  )
}

export default ConnectButton
