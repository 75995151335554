import React, {useState} from 'react'
// @mui
import { Stack, Grid, Card, CardContent, Popover, Box, Chip } from '@mui/material';
import { Facebook, LinkedIn, Telegram, YouTube, Instagram, Twitter, Reddit } from '@mui/icons-material';
import SettingMode from '../../../components/settings/SettingMode';
// assets
import { Certik, Share } from '../../../assets';

// ----------------------------------------------------------------------

export default function SidebarBottom() {

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Stack spacing={3} sx={{ px: 3, width: 1, textAlign: 'center', display: 'block', backgroundColor: "transparent" }}>
      <Card style={{backgroundColor: "transparent"}}>
        <CardContent>
          <Certik sx={{ width: 1}} />

          <Grid container sx={{ mt: 2, ml: 1}}>
            <Grid item xs={9} sm={9} md={8}>
              <SettingMode/>
            </Grid>
            <Grid item xs={3} sm={3} md={4}>
              <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Box sx={{px: 3, py: 3, width: '100%', justifyContent: 'center', alignItems: "center"}}>
                  <Stack direction="row" sx={{pb: 2}} spacing={1} alignItems="center" style={{alignItems: "center", justifyContent: "center"}}>
                      <Chip size="small" className='hover-primary' component="a" target="_blank" href={`https://twitter.com/realStabledoc`} icon={<Twitter />} variant="outlined" />
                      <Chip size="small" className='hover-primary' component="a" target="_blank" href={`https://web.facebook.com/stabledoc_-110369274829466`} icon={<Facebook />} variant="outlined" />
                      <Chip size="small" className='hover-primary' component="a" target="_blank" href={`https://t.me/stabledr`} icon={<Telegram />} variant="outlined" />
                      <Chip size="small" className='hover-primary' component="a" target="_blank" href={`https://www.linkedin.com/company/stabledoc/`} icon={<LinkedIn />} variant="outlined"/>
                  </Stack>
                  <Stack direction="row" spacing={1} alignItems="center" style={{alignItems: "center", justifyContent: "center"}}>
                      <Chip size="small" className='hover-primary' component="a" target="_blank" href={`https://www.youtube.com/channel/UCLMxvinm7lbCLOOQV9oz24A`} icon={<YouTube />} variant="outlined" />
                      <Chip size="small" className='hover-primary' component="a" target="_blank" href={`https://instagram.com/stabledoc_`} icon={<Instagram />} variant="outlined" />
                      <Chip size="small" className='hover-primary' component="a" target="_blank" href={``} icon={<Reddit />} variant="outlined"/>
                  </Stack>
              </Box>
              </Popover>
              <Share aria-describedby={id} style={{cursor: "pointer"}} onClick={handleClick}/>
            </Grid>
            
          </Grid>
        </CardContent>
      </Card>
      {/* <Button variant="contained">Documentation</Button> */}
    </Stack>
  );
}
