import * as React from 'react';
import PropTypes from 'prop-types';

import { 
  Button, 
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Dialog,
} from '@mui/material'

import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import { 
  BinanceSC,
  Ethereum,
  Solana
} from '../../../assets'


import ConnectButton from '../../../components/ConnectButton';

const networks = [
  {
    name: "BSC",
    icon: <BinanceSC/>,
  },
  {
    name: "ETH",
    icon: <Ethereum/>
  },
  {
    name: "SOL",
    icon: <Solana/>
  }
];

function ConnectWallet(props) {
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <List sx={{ p: 4 }}>
        {networks.map((network) => (
          <ListItem button onClick={() => handleListItemClick(network)} key={network.name}>
            <ListItemAvatar>
              {network.icon}
            </ListItemAvatar>
            <ListItemText primary={network.name} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}

ConnectWallet.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function SimpleDialogDemo() {
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(networks[0]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  return (
    <div>
      <Button>
        <ConnectButton/>
      </Button>
      <Button className="network-icon" onClick={handleClickOpen}>
        {selectedValue.icon} <ArrowDropDown/>
      </Button>
      
      <ConnectWallet
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
      />
    </div>
  );
}
