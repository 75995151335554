import contracts from './contracts'

const farms = [
  {
    pid: 0,
    lpSymbol: 'SDT',
    lpAddresses: {
      97: '0x063BD53fEF3F352eA9388c59c5aD9f524836A936',
      56: '0x543c7ebb52d56985f63f246a5b3558aff79037d7'
    },
    tokenSymbol: 'SDT',
    tokenAddresses: {
      97: '0x063BD53fEF3F352eA9388c59c5aD9f524836A936',
      56: '0x543c7ebb52d56985f63f246a5b3558aff79037d7'
    },
    quoteTokenSymbol: 'BNB',
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 1,
    lpSymbol: 'SDT-BNB LP',
    lpAddresses: {
      97: '0x7EDCC8e1dE60005AA8857503AdE42359595C8E73',
      56: '0x0fEEE985A67Dcb99f5FcCb38A579C8e18496F454'
    },
    tokenSymbol: 'SDT',
    tokenAddresses: {
      97: '0x063BD53fEF3F352eA9388c59c5aD9f524836A936',
      56: '0x543c7ebb52d56985f63f246a5b3558aff79037d7'
    },
    quoteTokenSymbol: 'BNB',
    quoteTokenAdresses: contracts.wbnb
  },
  {
    pid: 2,
    lpSymbol: 'BUSD-BNB LP',
    lpAddresses: {
      97: '0xe0e92035077c39594793e61802a350347c320cf2',
      56: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16'
    },
    tokenSymbol: 'BUSD',
    tokenAddresses: {
      97: '0x78867BbEeF44f2326bF8DDd1941a4439382EF2A7',
      56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    },
    quoteTokenSymbol: 'BNB',
    quoteTokenAdresses: contracts.wbnb
  },
  {
    pid: 3,
    lpSymbol: 'SDT-BUSD LP',
    lpAddresses: {
      97: '0x2Bf9D55f1C556d3B24bFDd2bcb1E2a00367bdFBD',
      56: '0x49DCa7dEd2E44CdfeD77c62890fa0540823D8b84'
    },
    tokenSymbol: 'SDT',
    tokenAddresses: {
      97: '0x063BD53fEF3F352eA9388c59c5aD9f524836A936',
      56: '0x543c7ebb52d56985f63f246a5b3558aff79037d7'
    },
    quoteTokenSymbol: 'BUSD',
    quoteTokenAdresses: contracts.busd
  },
  {
    pid: 4,
    lpSymbol: 'SDT-USDT LP',
    lpAddresses: {
      97: '0x9FE71439df508CecB29Af382507664ba31f08C3b',
      56: '0x86597e9F5033AA1d4ceC8C3a9331c7733Bf4ce50'
    },
    tokenSymbol: 'SDT',
    tokenAddresses: {
      97: '0x063BD53fEF3F352eA9388c59c5aD9f524836A936',
      56: '0x543c7ebb52d56985f63f246a5b3558aff79037d7'
    },
    quoteTokenSymbol: 'USDT',
    quoteTokenAdresses: contracts.usdt
  }
]

export default farms
