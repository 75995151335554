import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

export default function Logo({ ...other }) {

  return (
    <Box {...other}>
        
        <svg width="40" height="auto" viewBox="0 0 226 221" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_610_512)">
            <path fillRule="evenodd" clipRule="evenodd" d="M20.5674 180.266C17.9293 162.706 43.4233 159.283 46.3645 175.439C49.6327 193.388 22.8728 195.614 20.5674 180.266ZM97.0152 45.638C92.8433 53.3317 57.0356 83.6704 54.0507 90.5136C57.7417 95.0722 73.3173 111.602 77.0102 113.157C83.8382 116.032 90.479 112.164 91.5291 106.066C92.9023 98.0932 86.7281 94.9477 83.7755 90.5126C86.06 85.1215 123.45 49.6815 130.73 42.4801C138.327 34.965 144.983 26.014 157.988 22.247C184.775 14.4877 211.012 40.0017 203.831 66.789C200.229 80.2255 191.308 86.6496 183.807 94.2359C176.876 101.246 139.823 139.478 135.208 141.57C131.25 138.157 128.007 132.483 120.744 132.748C113.962 132.995 109.117 138.723 111.763 146.771C112.439 148.827 130.456 169.152 136.9 171.268C144.516 161.724 175.035 132.824 186.078 121.555C205.07 102.175 224.875 88.8116 225.52 60.1787C226.215 29.3629 207.066 8.6851 182.859 1.9949C168.231 -2.04772 151.762 0.813668 140.12 6.99639C128.069 13.3967 120.495 26.2982 112.77 29.8162C106.43 26.3647 101.222 18.7261 94.9369 13.3207C89.2084 8.3943 80.3543 3.6494 71.1724 1.51405C31.8571 -7.6289 -6.79302 25.8667 1.00428 68.8816C4.77512 89.683 19.2417 101.202 29.6761 111.567C41.7242 123.536 52.5568 134.63 64.5307 146.668C70.5471 152.717 75.6807 157.834 81.7655 163.745C86.6777 168.515 95.852 175.195 94.0806 186.595C92.7416 195.215 83.8866 201.717 72.6217 197.961C68.3947 196.551 65.6017 193.851 62.2034 191.214C56.2411 186.586 56.3466 188.221 56.4264 178.997C56.6355 154.926 29.4756 147.233 15.9527 162.644C2.16081 178.361 13.5569 201.138 34.9133 201.229C51.7224 201.3 57.9479 224.827 85.9963 219.485C103.261 216.198 117.491 199.835 115.079 179.371C112.919 161.043 94.8304 146.754 85.5259 137.547C73.4465 125.594 62.6985 114.412 50.544 102.586C41.5455 93.8301 22.584 79.3018 21.2716 61.5462C18.7352 27.22 54.9478 10.7444 79.2909 27.6315C83.7013 30.6905 94.7962 40.5034 97.0162 45.637L97.0152 45.638Z" fill="#00AB55"/>
            </g>
            <defs>
            <clipPath id="clip0_610_512">
            <rect width="225.545" height="220.275" fill="white"/>
            </clipPath>
            </defs>
        </svg>

    </Box>
  );
}