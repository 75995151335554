
const DOMAIN_API = "https://api.coingecko.com/api/v3/"
// const BTC_API

const ALL_INFO = "coins/markets?vs_currency=usd&ids=stabledoc-token&order=market_cap_desc%2C%20volume_desc&per_page=100&page=1&sparkline=false"
const SDT_HOLDERS = "https://api.covalenthq.com/v1/56/tokens/0x543c7ebb52d56985f63f246a5b3558aff79037d7/token_holders/?quote-currency=USD&format=JSON&page-size=100000000&key=ckey_52fc4db186c6413988b78b6fb9d"

const COVALENT_API_KEY = "ckey_52fc4db186c6413988b78b6fb9d"

export const coinGeckoApis = {
    livePrice: DOMAIN_API.concat("simple/price?ids=stabledoc-token&vs_currencies=usd"),
    marketCap: "",
    volume: "",
    liquidity: "",
    totalLocked: "",
    totalStaked: "",
    fullInfo: DOMAIN_API.concat(ALL_INFO),
    priceTimeline: DOMAIN_API.concat("coins/stabledoc-token/market_chart?vs_currency=usd&days=7&interval=daily")
}

export const coinPrices = {
    bitcoin: DOMAIN_API.concat("simple/price?ids=bitcoin&vs_currencies=usd"),
    solana: DOMAIN_API.concat("simple/price?ids=solana&vs_currencies=usd"),
    ethereum: DOMAIN_API.concat("simple/price?ids=ethereum&vs_currencies=usd"),
    binance: DOMAIN_API.concat("simple/price?ids=binancecoin&vs_currencies=usd"),
    stabledoc: DOMAIN_API.concat("simple/price?ids=stabledoc-token&vs_currencies=usd"),
    busd: DOMAIN_API.concat("simple/price?ids=binance-usd&vs_currencies=usd"),
    doge: DOMAIN_API.concat("simple/price?ids=dogecoin&vs_currencies=usd"),
    tether: DOMAIN_API.concat("simple/price?ids=tether&vs_currencies=usd"),
}

const fetchSdtLivePrice = fetch(coinGeckoApis.livePrice)
    .then(response => response.json())
    .then((data) => data['stabledoc-token'].usd)


export const getSdtLivePrice = async () => {
    const price = await fetchSdtLivePrice
    return price
}

const fetchBnbPrice = fetch(coinPrices.binance)
    .then(response => response.json())
    .then((data) => data.binancecoin.usd)

export const getBnbPrice = async () => {
    const price = await fetchBnbPrice
    return price
}

const fetchPriceTimeline = fetch(coinGeckoApis.priceTimeline)
    .then(response => response.json())
    .then((data) => data.prices)

export const getPriceTimeline = async () => {
    const timeline = await fetchPriceTimeline
    // console.log(timeline)
    return timeline
}


export const fetchSdtHolders = async (chainId, address) => {
    const getHolders = await fetch(`https://api.covalenthq.com/v1/${chainId}/tokens/${address}/token_holders/?quote-currency=USD&format=JSON&page-size=100000000&key=${COVALENT_API_KEY}`)
    const json = await getHolders.json()
    const data = json.data.items
    return data
}

// export const getSdtHolders = async (chainId, address) => {
//     const holders = await fetchSdtHolders(chainId, address)
//     return holders
// }