import { Box } from '@mui/material';

// ----------------------------------------------------------------------

export default function BinanceSC({ ...other }) {

  return (
    <Box {...other}>
        <svg width="30" height="auto" viewBox="0 0 368 368" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="184" cy="184" r="184" fill="black"/>
<path d="M124.463 184L100.583 207.91L76.6437 184L100.523 160.09L124.463 184ZM182.508 125.881L223.47 166.895L247.35 142.985L182.508 78L117.606 142.985L141.486 166.895L182.508 125.881ZM264.433 160.09L240.553 184L264.433 207.91L288.312 184L264.433 160.09ZM182.508 242.119L141.546 201.105L117.666 225.015L182.508 290L247.35 225.015L223.47 201.105L182.508 242.119ZM182.508 207.91L206.388 184L182.508 160.09L158.568 184L182.508 207.91Z" fill="#F0B90B"/>
</svg>
    </Box>
  );
}