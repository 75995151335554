import addresses from '../config/constants/contracts'

export const getAddress = (address) => {
  const mainnetChainId = 56
  const chainId = process.env.REACT_APP_CHAIN_ID
  return address[chainId] ? address[chainId] : address[mainnetChainId]
}

export const getSdtAddress = () => getAddress(addresses.sdt)
export const getMasterChefAddress = () => getAddress(addresses.masterChef)
export const getMulticallAddress = () => getAddress(addresses.multiCall)
export const getWbnbAddress = () => getAddress(addresses.wbnb)
