import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import CountUp from 'react-countup'
// @mui
import { styled } from '@mui/material/styles';
import { Box, Link, Typography } from '@mui/material';
import { getSdtLivePrice } from '../../../apis'

import CountAnimation from '../../../components/CountAnimation';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

NavbarAccount.propTypes = {
  isCollapse: PropTypes.bool,
};

export default function NavbarAccount({ isCollapse }) {

  const [livePrice, setLivePrice] = useState("0.00")
  // const { onCount } = useCountAnimation()

  useEffect(() => {

    getSdtLivePrice().then((price) => setLivePrice(price))

    const interval=setInterval(()=>{
      getSdtLivePrice()
      // countAnimate()
     },5000)
       
       
     return()=>clearInterval(interval)

  },[])

  // const countAnimate = () => <CountUp duration={5} prefix='$' decimals={6} decimal='.' start={0} end={parseFloat(livePrice)} />
  return (
    <Link underline="none" color="inherit">
      <RootStyle
        sx={{
          ...(isCollapse && {
            bgcolor: 'transparent',
          }),
        }}
      >
        {/* <Avatar src="https://minimal-assets-api.vercel.app/assets/images/avatars/avatar_5.jpg" alt="Rayan Moran" /> */}

        <Box
          sx={{
            transition: (theme) =>
              theme.transitions.create('width', {
                duration: theme.transitions.duration.shorter,
              }),
            ...(isCollapse && {
              ml: 0,
              width: 0,
            }),
          }}
        >
          <Typography variant="h6" color={'#00AB55'} noWrap sx={{ textAlign: "center" }}>
            Live Price ($USD)
          </Typography>
          <Typography noWrap sx={{ color: 'text.primary', textAlign: "center", fontSize: "32px", fontWeight: "bold" }}>
           {<CountAnimation duration={2} prefix='$' decimals={6} decimal='.' start={0} end={parseFloat(livePrice)} />}
          </Typography>
        </Box>
      </RootStyle>
    </Link>
  );
}
