
export function formatWalletAddress(address) {
    const initial = address.substring(0, 5)
    const lastChar = address.length
    const last = address.substring(lastChar - 5, lastChar)

    const result = initial.concat('...', last)
    return result
}

export const sdtToUsd = (data, livePrice) => {
    const priceInUsd = livePrice * Number(data)
    return "$".concat(String(priceInUsd.toFixed(2)))
}