import BigNumber from 'bignumber.js'
import BEP20ABI from '../../config/abi/BEP20.json'
import masterchefABI from '../../config/abi/masterchef.json'
import multicall from '../../utils/multicall'
import farmsConfig from '../../config/constants/farms'
import { getAddress, getMasterChefAddress } from '../../utils/addressHelpers'

export const fetchFarmUserAllowances = async (account) => {
  const masterChefAdress = getMasterChefAddress()

  const calls = farmsConfig.map(farm => {
    const lpContractAddress = getAddress(farm.lpAddresses)
    return { address: lpContractAddress, name: 'allowance', params: [account, masterChefAdress] }
  })

  const rawLpAllowances = await multicall(BEP20ABI, calls)
  const parsedLpAllowances = rawLpAllowances.map(lpBalance => new BigNumber(lpBalance).toJSON())
  return parsedLpAllowances
}

export const fetchFarmUserTokenBalances = async (account) => {
  const calls = farmsConfig.map(farm => {
    const lpContractAddress = getAddress(farm.lpAddresses)
    return {
      address: lpContractAddress,
      name: 'balanceOf',
      params: [account],
    }
  })

  const rawTokenBalances = await multicall(BEP20ABI, calls)
  const parsedTokenBalances = rawTokenBalances.map(tokenBalance => new BigNumber(tokenBalance).toJSON())
  return parsedTokenBalances
}

export const fetchFarmUserStakedBalances = async (account) => {
  const masterChefAdress = getMasterChefAddress()

  const calls = farmsConfig.map(farm => ({
    address: masterChefAdress,
    name: 'userInfo',
    params: [farm.pid, account],
  }))

  const rawStakedBalances = await multicall(masterchefABI, calls)
  const parsedStakedBalances = rawStakedBalances.map(stakedBalance => new BigNumber(stakedBalance[0]._hex).toJSON())
  return parsedStakedBalances
}

export const fetchFarmUserEarnings = async (account) => {
  const masterChefAdress = getMasterChefAddress()

  const calls = farmsConfig.map(farm => ({
    address: masterChefAdress,
    name: 'pendingSdt',
    params: [farm.pid, account],
  }))

  const rawEarnings = await multicall(masterchefABI, calls)
  const parsedEarnings = rawEarnings.map(earnings => new BigNumber(earnings).toJSON())
  return parsedEarnings
}

export const fetchFarmUserStakes = async (account) => {
  const masterChefAdress = getMasterChefAddress()

  const calls = farmsConfig.map(farm => ({
    address: masterChefAdress,
    name: 'userStakes',
    params: [farm.pid, account],
  }))

  const rawStakes = await multicall(masterchefABI, calls)
  const parsedStakes = rawStakes.map(stakes => stakes)
  return parsedStakes
}