import React from 'react'
import CountUp from 'react-countup'

const CountAnimation = props => {

    const { end, prefix, decimals, duration } = props

    return <CountUp duration={duration} prefix={prefix} decimals={decimals} decimal='.' start={0} end={end} />

}

export default CountAnimation