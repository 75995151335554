// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  user: getIcon('ic_user'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard_2'),
  news: getIcon('ic_news'),
  stake: getIcon('ic_stake'),
  farm: getIcon('ic_farm'),
  more: getIcon('ic_more'),
  trade: getIcon('ic_trade'),
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    // subheader: 'general v2.8.0',
    items: [
      { title: 'Dashboard', path: '/dashboard', icon: ICONS.dashboard },
      { 
        title: 'Trade', 
        path: 'https://pancakeswap.finance/swap?outputCurrency=0x543c7ebb52d56985f63f246a5b3558aff79037d7', 
        icon: ICONS.trade,
      },
      { title: 'Staking', path: '/stake', icon: ICONS.stake },
      { title: 'Farming', path: '/farm', icon: ICONS.farm },
      { title: 'News', path: '/news', icon: ICONS.news},
      { 
        title: 'More', 
        path: '', 
        icon: ICONS.more,
        children: [
          { title: 'Github', path: 'https://github.com/stabledoc/'},
          { title: 'Docs', path: 'https://stabledoc-1.gitbook.io/stabledoc'},
          { title: 'Coin MarketCap', path: 'https://coinmarketcap.com/currencies/stabledoc/'},
          { title: 'CoinGeeko', path: 'https://www.coingecko.com/en/coins/stabledoc-token'},
          { title: 'Audit', path: 'https://2055544600-files.gitbook.io/~/files/v0/b/gitbook-x-prod.appspot.com/o/spaces%2F78WmhQ7QzR9I5wJy7LG5%2Fuploads%2FyHNvC4M0LGl2G9RdiQit%2FStableDoc%20Staking%20%26%20Farming%20Audit%20Report%20by%20Certik.pdf?alt=media&token=1e42a9ae-407d-480d-8f9a-b06004d1d952'},
        ]
      }
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  // {
  //   // subheader: 'management',
  //   items: [
  //     {
  //       title: 'user',
  //       path: '/dashboard/user',
  //       icon: ICONS.user,
  //       children: [
  //         { title: 'Four', path: '/dashboard/user/four' },
  //         { title: 'Five', path: '/dashboard/user/five' },
  //         { title: 'Six', path: '/dashboard/user/six' },
  //       ],
  //     },
  //   ],
  // },
];

export default sidebarConfig;
