import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

export default function Binance({ ...other }) {

  return (
    <Box {...other}>
        
        <svg width="35" height="auto" viewBox="0 0 368 390" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="184" cy="195" r="184" fill="#F0B90B"/>
        <path d="M111.178 195L81.9699 224.246L52.6881 195L81.8963 165.755L111.178 195ZM182.175 123.912L232.278 174.079L261.486 144.833L182.175 65.3472L102.791 144.833L131.999 174.079L182.175 123.912ZM282.381 165.755L253.172 195L282.381 224.246L311.589 195L282.381 165.755ZM182.175 266.088L132.073 215.921L102.864 245.167L182.175 324.653L261.486 245.167L232.278 215.921L182.175 266.088ZM182.175 224.246L211.383 195L182.175 165.755L152.893 195L182.175 224.246Z" fill="white"/>
        </svg>

    </Box>
  );
}