import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

export default function Share({ ...other }) {

  return (
    <Box {...other}>
        
        <svg width="35" height="35" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="68" height="68" rx="34" fill="#EDEDED"/>
            <path d="M38 40.1977C37.2404 40.1977 36.5552 40.4941 36.0356 40.9713L28.91 36.7985C28.9652 36.5719 29 36.3357 29 36.0947C29 35.8538 28.9652 35.6188 28.91 35.3922L35.96 31.2604C36.512 31.7826 37.2416 32.0735 38 32.0737C39.6548 32.0737 41 30.7229 41 29.0613C41 27.3996 39.6548 26.0488 38 26.0488C36.3452 26.0488 35 27.3996 35 29.0613C35 29.3023 35.0348 29.5385 35.09 29.7638L28.04 33.8957C27.4877 33.374 26.7583 33.0831 26 33.0823C24.3452 33.0823 23 34.4331 23 36.0947C23 37.7564 24.3452 39.1072 26 39.1072C26.7584 39.107 27.488 38.8161 28.04 38.2938L35.1656 42.4667C35.1131 42.6821 35.0861 42.9029 35.0852 43.1246C35.0854 43.7031 35.2565 44.2686 35.5767 44.7496C35.897 45.2305 36.352 45.6053 36.8844 45.8266C37.4167 46.0479 38.0024 46.1057 38.5675 45.9928C39.1326 45.8799 39.6516 45.6013 40.059 45.1922C40.4664 44.7831 40.7439 44.2619 40.8564 43.6945C40.9688 43.1271 40.9112 42.5389 40.6908 42.0043C40.4705 41.4698 40.0972 41.0128 39.6183 40.6913C39.1393 40.3697 38.5762 40.1979 38 40.1977V40.1977Z" fill="#637381"/>
        </svg>

    </Box>
  );
}